import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _19a1a8e2 = () => interopDefault(import('../src/pages/contribute.vue' /* webpackChunkName: "pages/contribute" */))
const _7dad746a = () => interopDefault(import('../src/pages/cookies.vue' /* webpackChunkName: "pages/cookies" */))
const _2c887bc8 = () => interopDefault(import('../src/pages/cookies-en.vue' /* webpackChunkName: "pages/cookies-en" */))
const _23f355ed = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6c469c0e = () => interopDefault(import('../src/pages/events.vue' /* webpackChunkName: "pages/events" */))
const _01593575 = () => interopDefault(import('../src/pages/internal/index.vue' /* webpackChunkName: "pages/internal/index" */))
const _26296989 = () => interopDefault(import('../src/pages/marketold/index.vue' /* webpackChunkName: "pages/marketold/index" */))
const _3cf230e3 = () => interopDefault(import('../src/pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _09a48703 = () => interopDefault(import('../src/pages/privacy-en.vue' /* webpackChunkName: "pages/privacy-en" */))
const _e938a854 = () => interopDefault(import('../src/pages/refunds.vue' /* webpackChunkName: "pages/refunds" */))
const _dd1179a0 = () => interopDefault(import('../src/pages/refunds-en.vue' /* webpackChunkName: "pages/refunds-en" */))
const _641dd7bc = () => interopDefault(import('../src/pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _57336764 = () => interopDefault(import('../src/pages/terms-en.vue' /* webpackChunkName: "pages/terms-en" */))
const _0fee92ac = () => interopDefault(import('../src/pages/thankyou.vue' /* webpackChunkName: "pages/thankyou" */))
const _cd940fda = () => interopDefault(import('../src/pages/u/index.vue' /* webpackChunkName: "pages/u/index" */))
const _1a73d782 = () => interopDefault(import('../src/pages/where.vue' /* webpackChunkName: "pages/where" */))
const _3b9cad86 = () => interopDefault(import('../src/pages/internal/events.vue' /* webpackChunkName: "pages/internal/events" */))
const _3f63ff8c = () => interopDefault(import('../src/pages/internal/home.vue' /* webpackChunkName: "pages/internal/home" */))
const _a6d8786a = () => interopDefault(import('../src/pages/internal/list.vue' /* webpackChunkName: "pages/internal/list" */))
const _69fe4ee8 = () => interopDefault(import('../src/pages/internal/login.vue' /* webpackChunkName: "pages/internal/login" */))
const _38ad7b43 = () => interopDefault(import('../src/pages/internal/membership.vue' /* webpackChunkName: "pages/internal/membership" */))
const _a9373bfa = () => interopDefault(import('../src/pages/internal/news/index.vue' /* webpackChunkName: "pages/internal/news/index" */))
const _e94f1642 = () => interopDefault(import('../src/pages/internal/ticketcheck.vue' /* webpackChunkName: "pages/internal/ticketcheck" */))
const _1bfd5dc3 = () => interopDefault(import('../src/pages/internal/validate.vue' /* webpackChunkName: "pages/internal/validate" */))
const _713b6152 = () => interopDefault(import('../src/pages/marketold/calendar.vue' /* webpackChunkName: "pages/marketold/calendar" */))
const _7f7376c6 = () => interopDefault(import('../src/pages/marketold/contribute.vue' /* webpackChunkName: "pages/marketold/contribute" */))
const _1a081ef4 = () => interopDefault(import('../src/pages/u/forgot-password.vue' /* webpackChunkName: "pages/u/forgot-password" */))
const _62dd9b2a = () => interopDefault(import('../src/pages/u/login.vue' /* webpackChunkName: "pages/u/login" */))
const _0f681618 = () => interopDefault(import('../src/pages/u/manage.vue' /* webpackChunkName: "pages/u/manage" */))
const _4417ab65 = () => interopDefault(import('../src/pages/u/membership.vue' /* webpackChunkName: "pages/u/membership" */))
const _4e46951c = () => interopDefault(import('../src/pages/u/register.vue' /* webpackChunkName: "pages/u/register" */))
const _3a094fb1 = () => interopDefault(import('../src/pages/u/confirm/activate.vue' /* webpackChunkName: "pages/u/confirm/activate" */))
const _69f1f8d7 = () => interopDefault(import('../src/pages/u/confirm/expired.vue' /* webpackChunkName: "pages/u/confirm/expired" */))
const _23dcf2b5 = () => interopDefault(import('../src/pages/u/confirm/success.vue' /* webpackChunkName: "pages/u/confirm/success" */))
const _46df25ea = () => interopDefault(import('../src/pages/u/confirm/trigger.vue' /* webpackChunkName: "pages/u/confirm/trigger" */))
const _7529f38d = () => interopDefault(import('../src/pages/u/recover/activate.vue' /* webpackChunkName: "pages/u/recover/activate" */))
const _007f697b = () => interopDefault(import('../src/pages/u/recover/expired.vue' /* webpackChunkName: "pages/u/recover/expired" */))
const _8b2b394e = () => interopDefault(import('../src/pages/u/recover/success.vue' /* webpackChunkName: "pages/u/recover/success" */))
const _3d0bdaeb = () => interopDefault(import('../src/pages/internal/event/_id/index.vue' /* webpackChunkName: "pages/internal/event/_id/index" */))
const _7d99092b = () => interopDefault(import('../src/pages/internal/news/_id.vue' /* webpackChunkName: "pages/internal/news/_id" */))
const _32a0c6fd = () => interopDefault(import('../src/pages/internal/view/_id.vue' /* webpackChunkName: "pages/internal/view/_id" */))
const _3d28f73a = () => interopDefault(import('../src/pages/u/confirm/_confirmId.vue' /* webpackChunkName: "pages/u/confirm/_confirmId" */))
const _32b01416 = () => interopDefault(import('../src/pages/u/recover/_confirmId.vue' /* webpackChunkName: "pages/u/recover/_confirmId" */))
const _2542fc2e = () => interopDefault(import('../src/pages/internal/event/_id/lists.vue' /* webpackChunkName: "pages/internal/event/_id/lists" */))
const _c4143240 = () => interopDefault(import('../src/pages/event/_id.vue' /* webpackChunkName: "pages/event/_id" */))
const _06c0b3b4 = () => interopDefault(import('../src/pages/internal/_404.vue' /* webpackChunkName: "pages/internal/_404" */))
const _2b8175b3 = () => interopDefault(import('../src/pages/news/_id.vue' /* webpackChunkName: "pages/news/_id" */))
const _a21dd070 = () => interopDefault(import('../src/pages/u/_404.vue' /* webpackChunkName: "pages/u/_404" */))
const _168396ae = () => interopDefault(import('../src/pages/_404.vue' /* webpackChunkName: "pages/_404" */))
const _e6acd7f6 = () => interopDefault(import('../src/pages/_oldindex.vue' /* webpackChunkName: "pages/_oldindex" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/contribute",
    component: _19a1a8e2,
    name: "contribute___it"
  }, {
    path: "/cookies",
    component: _7dad746a,
    name: "cookies___it"
  }, {
    path: "/cookies-en",
    component: _2c887bc8,
    name: "cookies-en___it"
  }, {
    path: "/en",
    component: _23f355ed,
    name: "index___en"
  }, {
    path: "/events",
    component: _6c469c0e,
    name: "events___it"
  }, {
    path: "/internal",
    component: _01593575,
    name: "internal___it"
  }, {
    path: "/marketold",
    component: _26296989,
    name: "marketold___it"
  }, {
    path: "/privacy",
    component: _3cf230e3,
    name: "privacy___it"
  }, {
    path: "/privacy-en",
    component: _09a48703,
    name: "privacy-en___it"
  }, {
    path: "/refunds",
    component: _e938a854,
    name: "refunds___it"
  }, {
    path: "/refunds-en",
    component: _dd1179a0,
    name: "refunds-en___it"
  }, {
    path: "/terms",
    component: _641dd7bc,
    name: "terms___it"
  }, {
    path: "/terms-en",
    component: _57336764,
    name: "terms-en___it"
  }, {
    path: "/thankyou",
    component: _0fee92ac,
    name: "thankyou___it"
  }, {
    path: "/u",
    component: _cd940fda,
    name: "u___it"
  }, {
    path: "/where",
    component: _1a73d782,
    name: "where___it"
  }, {
    path: "/en/contribute",
    component: _19a1a8e2,
    name: "contribute___en"
  }, {
    path: "/en/cookies",
    component: _7dad746a,
    name: "cookies___en"
  }, {
    path: "/en/cookies-en",
    component: _2c887bc8,
    name: "cookies-en___en"
  }, {
    path: "/en/events",
    component: _6c469c0e,
    name: "events___en"
  }, {
    path: "/en/internal",
    component: _01593575,
    name: "internal___en"
  }, {
    path: "/en/marketold",
    component: _26296989,
    name: "marketold___en"
  }, {
    path: "/en/privacy",
    component: _3cf230e3,
    name: "privacy___en"
  }, {
    path: "/en/privacy-en",
    component: _09a48703,
    name: "privacy-en___en"
  }, {
    path: "/en/refunds",
    component: _e938a854,
    name: "refunds___en"
  }, {
    path: "/en/refunds-en",
    component: _dd1179a0,
    name: "refunds-en___en"
  }, {
    path: "/en/terms",
    component: _641dd7bc,
    name: "terms___en"
  }, {
    path: "/en/terms-en",
    component: _57336764,
    name: "terms-en___en"
  }, {
    path: "/en/thankyou",
    component: _0fee92ac,
    name: "thankyou___en"
  }, {
    path: "/en/u",
    component: _cd940fda,
    name: "u___en"
  }, {
    path: "/en/where",
    component: _1a73d782,
    name: "where___en"
  }, {
    path: "/internal/events",
    component: _3b9cad86,
    name: "internal-events___it"
  }, {
    path: "/internal/home",
    component: _3f63ff8c,
    name: "internal-home___it"
  }, {
    path: "/internal/list",
    component: _a6d8786a,
    name: "internal-list___it"
  }, {
    path: "/internal/login",
    component: _69fe4ee8,
    name: "internal-login___it"
  }, {
    path: "/internal/membership",
    component: _38ad7b43,
    name: "internal-membership___it"
  }, {
    path: "/internal/news",
    component: _a9373bfa,
    name: "internal-news___it"
  }, {
    path: "/internal/ticketcheck",
    component: _e94f1642,
    name: "internal-ticketcheck___it"
  }, {
    path: "/internal/validate",
    component: _1bfd5dc3,
    name: "internal-validate___it"
  }, {
    path: "/marketold/calendar",
    component: _713b6152,
    name: "marketold-calendar___it"
  }, {
    path: "/marketold/contribute",
    component: _7f7376c6,
    name: "marketold-contribute___it"
  }, {
    path: "/u/forgot-password",
    component: _1a081ef4,
    name: "u-forgot-password___it"
  }, {
    path: "/u/login",
    component: _62dd9b2a,
    name: "u-login___it"
  }, {
    path: "/u/manage",
    component: _0f681618,
    name: "u-manage___it"
  }, {
    path: "/u/membership",
    component: _4417ab65,
    name: "u-membership___it"
  }, {
    path: "/u/register",
    component: _4e46951c,
    name: "u-register___it"
  }, {
    path: "/en/internal/events",
    component: _3b9cad86,
    name: "internal-events___en"
  }, {
    path: "/en/internal/home",
    component: _3f63ff8c,
    name: "internal-home___en"
  }, {
    path: "/en/internal/list",
    component: _a6d8786a,
    name: "internal-list___en"
  }, {
    path: "/en/internal/login",
    component: _69fe4ee8,
    name: "internal-login___en"
  }, {
    path: "/en/internal/membership",
    component: _38ad7b43,
    name: "internal-membership___en"
  }, {
    path: "/en/internal/news",
    component: _a9373bfa,
    name: "internal-news___en"
  }, {
    path: "/en/internal/ticketcheck",
    component: _e94f1642,
    name: "internal-ticketcheck___en"
  }, {
    path: "/en/internal/validate",
    component: _1bfd5dc3,
    name: "internal-validate___en"
  }, {
    path: "/en/marketold/calendar",
    component: _713b6152,
    name: "marketold-calendar___en"
  }, {
    path: "/en/marketold/contribute",
    component: _7f7376c6,
    name: "marketold-contribute___en"
  }, {
    path: "/en/u/forgot-password",
    component: _1a081ef4,
    name: "u-forgot-password___en"
  }, {
    path: "/en/u/login",
    component: _62dd9b2a,
    name: "u-login___en"
  }, {
    path: "/en/u/manage",
    component: _0f681618,
    name: "u-manage___en"
  }, {
    path: "/en/u/membership",
    component: _4417ab65,
    name: "u-membership___en"
  }, {
    path: "/en/u/register",
    component: _4e46951c,
    name: "u-register___en"
  }, {
    path: "/u/confirm/activate",
    component: _3a094fb1,
    name: "u-confirm-activate___it"
  }, {
    path: "/u/confirm/expired",
    component: _69f1f8d7,
    name: "u-confirm-expired___it"
  }, {
    path: "/u/confirm/success",
    component: _23dcf2b5,
    name: "u-confirm-success___it"
  }, {
    path: "/u/confirm/trigger",
    component: _46df25ea,
    name: "u-confirm-trigger___it"
  }, {
    path: "/u/recover/activate",
    component: _7529f38d,
    name: "u-recover-activate___it"
  }, {
    path: "/u/recover/expired",
    component: _007f697b,
    name: "u-recover-expired___it"
  }, {
    path: "/u/recover/success",
    component: _8b2b394e,
    name: "u-recover-success___it"
  }, {
    path: "/en/u/confirm/activate",
    component: _3a094fb1,
    name: "u-confirm-activate___en"
  }, {
    path: "/en/u/confirm/expired",
    component: _69f1f8d7,
    name: "u-confirm-expired___en"
  }, {
    path: "/en/u/confirm/success",
    component: _23dcf2b5,
    name: "u-confirm-success___en"
  }, {
    path: "/en/u/confirm/trigger",
    component: _46df25ea,
    name: "u-confirm-trigger___en"
  }, {
    path: "/en/u/recover/activate",
    component: _7529f38d,
    name: "u-recover-activate___en"
  }, {
    path: "/en/u/recover/expired",
    component: _007f697b,
    name: "u-recover-expired___en"
  }, {
    path: "/en/u/recover/success",
    component: _8b2b394e,
    name: "u-recover-success___en"
  }, {
    path: "/",
    component: _23f355ed,
    name: "index___it"
  }, {
    path: "/en/internal/event/:id",
    component: _3d0bdaeb,
    name: "internal-event-id___en"
  }, {
    path: "/en/internal/news/:id",
    component: _7d99092b,
    name: "internal-news-id___en"
  }, {
    path: "/en/internal/view/:id?",
    component: _32a0c6fd,
    name: "internal-view-id___en"
  }, {
    path: "/en/u/confirm/:confirmId?",
    component: _3d28f73a,
    name: "u-confirm-confirmId___en"
  }, {
    path: "/en/u/recover/:confirmId?",
    component: _32b01416,
    name: "u-recover-confirmId___en"
  }, {
    path: "/en/internal/event/:id?/lists",
    component: _2542fc2e,
    name: "internal-event-id-lists___en"
  }, {
    path: "/en/event/:id?",
    component: _c4143240,
    name: "event-id___en"
  }, {
    path: "/en/internal/:404",
    component: _06c0b3b4,
    name: "internal-404___en"
  }, {
    path: "/en/news/:id?",
    component: _2b8175b3,
    name: "news-id___en"
  }, {
    path: "/en/u/:404",
    component: _a21dd070,
    name: "u-404___en"
  }, {
    path: "/internal/event/:id",
    component: _3d0bdaeb,
    name: "internal-event-id___it"
  }, {
    path: "/internal/news/:id",
    component: _7d99092b,
    name: "internal-news-id___it"
  }, {
    path: "/internal/view/:id?",
    component: _32a0c6fd,
    name: "internal-view-id___it"
  }, {
    path: "/u/confirm/:confirmId?",
    component: _3d28f73a,
    name: "u-confirm-confirmId___it"
  }, {
    path: "/u/recover/:confirmId?",
    component: _32b01416,
    name: "u-recover-confirmId___it"
  }, {
    path: "/internal/event/:id?/lists",
    component: _2542fc2e,
    name: "internal-event-id-lists___it"
  }, {
    path: "/en/:404",
    component: _168396ae,
    name: "404___en"
  }, {
    path: "/en/:oldindex",
    component: _e6acd7f6,
    name: "oldindex___en"
  }, {
    path: "/event/:id?",
    component: _c4143240,
    name: "event-id___it"
  }, {
    path: "/internal/:404",
    component: _06c0b3b4,
    name: "internal-404___it"
  }, {
    path: "/news/:id?",
    component: _2b8175b3,
    name: "news-id___it"
  }, {
    path: "/u/:404",
    component: _a21dd070,
    name: "u-404___it"
  }, {
    path: "/:404",
    component: _168396ae,
    name: "404___it"
  }, {
    path: "/:oldindex",
    component: _e6acd7f6,
    name: "oldindex___it"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
