export default {
    validate: {
        header: "Scanner QR",
        title: "Scanner QR",
        description: "Inquadra un QR code per validare una tessera",
        startlabel: "Inizia",
        meta: { title: "Scanner QR", description: "" },
    },
    ticketcheck: {
        header: "Scanner biglietti",
        title: "Scanner biglietti",
        description: "Inquadra un QR code per validare un biglietto",
        startlabel: "Inizia",
        fields: {
            orderId: "ID ordine",
            buyerName: "Nome",
            eventStart: "Data evento",
            eventTitle: "Titolo evento",
            ticketType: "Tipo biglietto",
            ticketStatus: "Stato biglietto",
            ticketPrice: "Prezzo biglietto",
            createdat: "Data acquisto",
        },
        meta: { title: "Scanner biglietti", description: "" },
    },
    internalDashboard: {
        header: "Dashboard",
        title: "Dashboard",
        description: "Here you can find your personal information.",
        meta: { title: "DASHBOARD", description: "" },
    },
    comp: {
        nextup: {
            header: "Prossimi eventi",
        },
    },
    actions: {
        logout: "Logout",
    },
    market: {
        header: "Market",
        title: "Market",
        description: "Pagina del Maramao market",
        meta: { title: "MARKET", description: "" },
        calendar: {
            header: "Calendario",
            title: "Calendario",
            description: "Date del Maramao market",
            meta: { title: "DATE", description: "" },
        },
        contribute: {
            header: "Collabora",
            title: "Collabora col Maramao market",
            description:
                "Vuoi collaborare con il Maramao market? Scrivici riguardo la categoria che ti interessa",
            meta: { title: "COLLABORA", description: "" },
        },
        gallery: {
            header: "Galleria",
            title: "Galleria",
            description: "Galleria del Maramao market",
            meta: { title: "GALLERIA", description: "" },
        },
    },
    register: {
        header: "Registrati",
        description: "Crea un nuovo account",
        form: {
            firstName: {
                label: "Nome",
                placeholder: "Inserisci il tuo nome",
            },
            lastName: {
                label: "Cognome",
                placeholder: "Inserisci il tuo cognome",
            },
            email: {
                label: "E-mail",
                placeholder: "Inserisci il tuo indirizzo e-mail",
            },
            password: {
                label: "Password",
                placeholder: "Inserisci la tua password",
            },
            passwordConfirm: {
                label: "Conferma password",
                placeholder: "Conferma la tua password",
            },
            submit: "Registrati",
        },

        meta: { title: "REGISTRATI", description: "" },
        loginquestion: "Hai già un account?",
        login: "Accedi",
    },
    form: {
        errors: {
            invalid:
                "Si è verificato un errore. Per favore verifica i dati inseriti.",
            not_found:
                "Account non trovato. Controlla i dati inseriti, oppure registrati.",
            already_registered:
                "L'indirizzo e-mail è già registrato. Effettua il login o recupera la password.",
            generic: "Si è verificato un errore. Riprova.",
            mail_failure:
                "Si è verificato un errore nell'invio della mail di conferma. Riprova.",
        },
    },
    passwordchange: {
        header: "Cambia password",
        description: "Inserisci la tua nuova password.",
        form: {
            password: {
                label: "Nuova password",
                placeholder: "Inserisci la tua nuova password",
            },
            passwordConfirm: {
                label: "Conferma password",
                placeholder: "Conferma la tua nuova password",
            },
            submit: "Cambia password",
        },
        meta: { title: "CAMBIA PASSWORD", description: "" },
    },
    forgot: {
        header: "Recupera password",
        description:
            "Inserisci la tua e-mail per recuperare le credenziali di accesso.",
        form: {
            email: {
                label: "E-mail",
                placeholder: "Inserisci il tuo indirizzo e-mail",
            },
            submit: "Recupera password",
        },
        meta: { title: "PASSWORD DIMENTICATA", description: "" },
        loginquestion: "Alternativamente,",
        or: "o",
        login: "Accedi",
        register: "Registrati",
        contactus: "Se invece non ricordi l'indirizzo e-mail,",
        contactuslink: "contattaci",
    },
    login: {
        header: "Accedi",
        description: "Inserisci le tue credenziali per continuare.",
        form: {
            email: {
                label: "E-mail",
                placeholder: "Inserisci il tuo indirizzo e-mail",
            },
            password: {
                label: "Password",
                placeholder: "Inserisci la tua password",
            },
            submit: "Accedi",
            accountnotfound:
                "L'e-mail o la password inserite non sono corrette. Riprova.",
        },
        meta: { title: "ACCEDI", description: "" },
        forgotpassword: "Password dimenticata?",
        registerquestion: "Non hai un account? ",
        register: "Registrati",
    },

    internal: {
        snackbar: {
            title: "Installa TDF*P Admin",
            description:
                "Installa la web-app di gestione sul tuo dispositivo per la miglior esperienza.",
            instructions1: "Fai tap su",
            instructions2: "e poi",
            instructions3: "Aggiungi alla schermata Home",
            action: "Installa",
            dismiss: "Continua",
        },
    },
    manageAccount: {
        header: "Gestisci account",
        changepwdform: {
            title: "Cambia password",
            password: {
                label: "Vecchia password",
                placeholder: "Inserisci la tua vecchia password",
            },
            newpassword: {
                label: "Nuova password",
                placeholder: "Inserisci la tua nuova password",
            },
            newpasswordConfirm: {
                label: "Conferma password",
                placeholder: "Conferma la tua nuova password",
            },
            submit: "Salva",
        },
        deleteaccForm: {
            title: "Elimina account",
            confirm: "Conferma eliminazione",
            description:
                "Eliminando il tuo account, la tua utenza verrà disattivata e dovrai registrarti nuovamente. Questa azione è irreversibile.",
            description2:
                "Eventuali tessere in corso di validità verranno eliminate dai nostri sistemi entro 30 giorni dalla data di tesseramento. Ti invieremo una copia della tessera via e-mail, che potrai esibire all'ingresso previa verifica dei registri di accesso.",
            question:
                "Sei sicuro di voler eliminare il tuo account? Questa azione è irreversibile.",
            password: {
                label: "Password",
                placeholder: "Inserisci la tua password",
            },
            submit: "Elimina",
            cancel: "Annulla",
        },
        meta: { title: "GESTISCI ACCOUNT", description: "" },
    },
    membershipcard: {
        header: "Tessera",
        type: "Tipo tessera",
        validity: "Validità",
        dob: "Data di nascita",
        firstName: "Nome",
        lastName: "Cognome",
    },
    managehome: {
        header: "Gestisci home",
        title: "Gestisci home",
        description:
            "Qui puoi gestire i tuoi eventi, i tuoi membri e le tue tessere.",
        meta: { title: "HOME", description: "" },
        form: {
            announcements: {
                label: "Annunci in home",
                placeholder: "Inserisci un annuncio",
            },
            featured: {
                label: "Eventi in evidenza",
                placeholder: "Inserisci un evento in evidenza",
            },
            paragraph: {
                labelIt: "Paragrafo (italiano)",
                labelEn: "Paragrafo (inglese)",
                placeholder: "Inserisci un paragrafo",
            },
            submit: "Salva",
        },
    },
    manageevents: {
        header: "Gestisci eventi",
        title: "Gestisci eventi",

        description:
            "Qui puoi gestire i tuoi eventi, i tuoi membri e le tue tessere.",
        meta: { title: "GESTIONE", description: "" },
        lists: "Liste accrediti",

        controls: {
            add: "Crea nuovo evento",
            type: "Tipo evento",
            search: "Cerca",
            past: "Passati",
        },
        deleteModal: {
            title: "Elimina evento",
            description: "Sei sicurə di voler eliminare ",
            warning: "Questa azione è irreversibile.",
            submit: "Elimina",
            cancel: "Annulla",
        },
        soldout: "Sold out",
        paid: "A pagamento",
        seats: "Disponibili",
        public: "Pubblico",
        private: "Non visibile",
        upcoming: "Eventi in programma",
        noupcoming: "Nessun evento in programma",
        past: "Eventi passati",
        nopast: "Nessun evento passato",
        form: {
            category: {
                label: "Categoria",
                placeholder: "Seleziona una categoria",
            },
            color: {
                label: "Colore",
                placeholder: "Inserisci il colore dell'evento",
            },
            colors: {
                darkred: "Rosso",
                orange: "Arancione",
                yellow: "Giallo",
                green: "Verde",
                blue: "Blu",
                purple: "Viola",
                pink: "Rosa",
                lightblue: "Azzurro",
                black: "Nero",
                white: "Bianco",
            },
            paid: {
                label: "A pagamento",
                placeholder: "Seleziona se l'evento è a pagamento",
            },
            public: {
                label: "Pubblico",
                placeholder: "Seleziona se l'evento è pubblico",
            },
            price: {
                label: "Prezzo",
                placeholder: "Inserisci il prezzo dell'evento",
            },
            seats: {
                label: "Posti disponibili",
                placeholder: "Inserisci il numero di posti disponibili",
            },
            image: {
                delete: "Elimina immagine",
                label: "Immagine",
                placeholder: "Inserisci l'immagine dell'evento",
                drop: "Clicca o trascina qui l'immagine",
            },
            title: {
                label: "Titolo",
                placeholder: "Titolo dell'evento",
            },
            summary: {
                label: "Descrizione",
                placeholder: "Inserisci una descrizione dell'evento",
            },
            start: {
                label: "Data inizio",
                placeholder: "Inserisci la data di inizio dell'evento",
            },
            end: {
                label: "Data fine",
                placeholder: "Inserisci la data di fine dell'evento",
            },
            backgroundColor: {
                label: "Colore di sfondo",
                placeholder: "Inserisci il colore di sfondo dell'evento",
            },
            link: {
                label: "Link",
                placeholder: "Inserisci il link dell'evento",
            },
            delete: "Elimina",
            submit: "Salva",
        },
    },
    managenews: {
        header: "Gestisci annunci",
        title: "Gestisci annunci",

        description: "Qui puoi gestire i tuoi annunci.",
        meta: { title: "GESTIONE", description: "" },
        controls: {
            add: "Crea nuovo annuncio",
            type: "Tipo annuncio",
            search: "Cerca",
            past: "Passati",
        },
        deleteModal: {
            title: "Elimina annuncio",
            description: "Sei sicurə di voler eliminare ",
            warning: "Questa azione è irreversibile.",
            submit: "Elimina",
            cancel: "Annulla",
        },
        soldout: "Sold out",
        paid: "A pagamento",
        seats: "Disponibili",
        public: "Pubblico",
        private: "Non visibile",
        upcoming: "Eventi in programma",
        noupcoming: "Nessun annuncio in programma",
        past: "Eventi passati",
        nopast: "Nessun annuncio passato",
        form: {
            category: {
                label: "Categoria",
                placeholder: "Seleziona una categoria",
            },
            color: {
                label: "Colore",
                placeholder: "Inserisci il colore dell'evento",
            },
            colors: {
                darkred: "Rosso",
                orange: "Arancione",
                yellow: "Giallo",
                green: "Verde",
                blue: "Blu",
                purple: "Viola",
                pink: "Rosa",
                lightblue: "Azzurro",
                black: "Nero",
                white: "Bianco",
            },

            public: {
                label: "Pubblico",
                placeholder: "Seleziona se l'annuncio è pubblico",
            },

            image: {
                delete: "Elimina immagine",
                label: "Immagine",
                placeholder: "Inserisci l'immagine dell'annuncio",
                drop: "Clicca o trascina qui l'immagine",
            },
            title: {
                label: "Titolo",
                placeholder: "Titolo dell'annuncio",
            },
            summary: {
                label: "Descrizione",
                placeholder: "Inserisci una descrizione dell'annuncio",
            },
            start: {
                label: "Data di pubblicazione",
                placeholder: "Inserisci la data di inizio dell'annuncio",
            },

            backgroundColor: {
                label: "Colore di sfondo",
                placeholder: "Inserisci il colore di sfondo dell'annuncio",
            },
            membersOnly: {
                label: "Riservato ai tesserati",
                placeholder: "Seleziona se l'annuncio è riservato ai tesserati",
            },
            featured: {
                label: "In evidenza (home)",
                placeholder: "Seleziona se l'annuncio è in evidenza",
            },

            delete: "Elimina",
            submit: "Salva",
        },
    },
    categories: {
        internal: {
            manage: {
                header: "Gestione",
            },
            scanner: {
                header: "Scanner QR",
            },
            home: {
                header: "Home",
            },
            members: {
                header: "Tesserati",
            },
            news: {
                header: "Annunci",
            },
            events: {
                header: "Eventi",
            },
            memberships: {
                header: "Tessere",
            },
            tickets: {
                header: "Biglietti",
            },
            dashboard: {
                header: "Dashboard",
            },
        },
    },
    manage: {
        header: "Gestisci tesserati",
        title: "Gestisci tesserati",
        description:
            "Qui puoi gestire i tuoi eventi, i tuoi membri e le tue tessere.",
        meta: { title: "GESTIONE", description: "" },
        controls: {
            showing: "",
            to: "a",
            of: "di",
            members: "tesserati",
            new: "Crea tessera",
            type: "Tipo tessera",
            search: "Cerca",
            valid: "In corso",
            expired: "Scadute",
            fiscalCode: "Cod. fiscale",
            lastName: "Cognome",
            nationalId: "ID estero",
            searchByLN: "Cerca per cognome",
            searchByID: "Cerca per codice",
        },
    },

    profile: {
        tickets: {
            description:
                "Qui troverai tutti i biglietti che hai acquistato e che hai attualmente in corso.",
            empty: "Non ci sono biglietti attivi nel tuo account.",
            title: "I tuoi biglietti",
            qrCode: "Puoi mostrare il QR code all'ingresso aprendo l'app dal tuo dispositivo mobile.",
            type: "Type: ",
            status: "Validità",
            showQR: "Mostra codice QR",
            hideQR: "Nascondi codice QR",
            date: "Data",
            event: "Evento",
            time: "Ora",
        },
        manage: {
            header: "Gestisci account",
            title: "Gestisci account",
            description:
                "Qui puoi modificare le tue informazioni personali e gestire le tue tessere.",
            form: {
                firstName: {
                    label: "Nome",
                    placeholder: "Inserisci il tuo nome",
                },
                lastName: {
                    label: "Cognome",
                    placeholder: "Inserisci il tuo cognome",
                },
                email: {
                    label: "E-mail",
                    placeholder: "Inserisci il tuo indirizzo e-mail",
                },
                oldpassword: {
                    label: "Vecchia password",
                    placeholder: "Inserisci la tua vecchia password",
                },
                password: {
                    label: "Nuova password",
                    placeholder: "Inserisci la tua nuova password",
                },
                passwordConfirm: {
                    label: "Conferma password",
                    placeholder: "Conferma la tua nuova password",
                },
                submit: "Salva",
            },
            meta: { title: "GESTISCI ACCOUNT", description: "" },
        },
        header: "Profilo",
        description: "Modifica le tue informazioni personali.",
        manageAccount: "Gestisci account",
        announcements: {
            title: "Comunicazioni",
        },
        memberships: {
            qrCode: "Puoi mostrare il QR code all'ingresso aprendo l'app dal tuo dispositivo mobile.",
            title: "Le tue tessere",
            description:
                "Qui troverai tutte le tessere che hai richiesto e che hai attualmente in corso.",
            empty: "Non ci sono tessere attive nel tuo account.",
            type: "Tipo:",
            status: "Validità:",
            showQR: "Mostra codice QR",
            hideQR: "Nascondi codice QR",
            request: "Richiedi una nuova tessera",
            email: "Hai ricevuto una tessera via e-mail?",
            recover: "Recupera tessera",
        },
        meta: { title: "PROFILO", description: "" },
    },
    contribute: {
        header: "Collabora",
        title: "Collabora col Tempio",
        description:
            "Vuoi collaborare con Tempio Del Futuro *Perduto? Scrivici riguardo la categoria che ti interessa",
        form: {
            selectCategory: "Seleziona una categoria",
            submit: "Invia",
            firstName: { label: "Nome", placeholder: "Inserisci il tuo nome" },
            lastName: {
                label: "Cognome",
                placeholder: "Inserisci il tuo cognome",
            },
            category: {
                label: "Categoria",
                placeholder: "Seleziona una categoria",
            },
            email: {
                label: "E-mail",
                placeholder: "Inserisci il tuo indirizzo e-mail",
            },
            message: {
                label: "Messaggio",
                placeholder: "Scrivi qui il tuo messaggio...",
            },
            subscribe:
                "Accetto l'informativa a tutela della privacy e presto il consenso a ricevere comunicazioni da TDF*P.",
        },
        meta: { title: "COLLABORA", description: "" },
    },
    exhibit: {
        header: "Mostre",
    },
    app: {
        header: "App",
        title: "🏛️",
        description: "Qui andrà la dashboard utente / nuova home page Tempio",
        snackbar: {
            title: "Installa TDF*P",
            description:
                "Installa la web-app sul tuo dispositivo per la miglior esperienza.",
            instructions1: "Fai tap su",
            instructions2: "e poi",
            instructions3: "Aggiungi alla schermata Home",
            action: "Installa",
            dismiss: "Continua",
        },
    },
    internalsubscribe: {
        header: "Crea tessera",
        title: "Crea tessera",
        description:
            "Qui puoi creare una nuova tessera se l'utente non ha un account.",
        instructions:
            "La tessera verrà inviata all'indirizzo e-mail dell'utente. Se l'utente registra un account in seguito con lo stesso indirizzo e-mail, la tessera verrà aggiunta automaticamente al suo profilo.",
        warning: "La tessera ha validità di due anni.",
        form: {
            selectCategory: "Seleziona una categoria",
            submit: "Invia",
            firstName: {
                label: "Nome",
                disclaimer:
                    "Se il nome sul documento non corrisponde alla tua identità di genere, non preoccuparti. Questa informazione ci è necessaria solo alla validazione del tuo codice fiscale.",
                placeholder: "Inserisci il tuo nome",
            },
            lastName: {
                label: "Cognome",
                placeholder: "Inserisci il tuo cognome",
            },
            type: {
                label: "Tipo di tessera",
                placeholder: "Seleziona il tipo di tessera",
                description0:
                    "La tessera per gli eventi serali verrà rilasciata in app e potrai esibirla per accedere all'ingresso.",
                description1:
                    "La tessera per corsi, workshop e laboratori verrà rilasciata via mail che potrai esibire per accedere all'ingresso.",
            },
            category: {
                label: "Categoria",
                placeholder: "Seleziona una categoria",
            },
            address: {
                label: "Indirizzo",
                placeholder: "Inserisci un indirizzo",
            },
            country: {
                label: "Nazione di nascita",
                placeholder: "Inizia a scrivere una nazione di nascita",
            },
            gender: {
                label: "Sesso biologico",
                placeholder: "Seleziona un sesso biologico",
                disclaimer:
                    "Se il sesso sul documento non corrisponde alla tua identità di genere, non preoccuparti. Non collezioniamo questo dato, ci è necessario solo alla validazione del tuo codice fiscale.",
            },
            dob: {
                label: "Data di nascita",
                placeholder: "Inserisci una data di nascita",
            },
            email: {
                label: "E-mail",
                placeholder: "Inserisci il tuo indirizzo e-mail",
            },
            message: {
                label: "Messaggio",
                placeholder: "Scrivi qui il tuo messaggio",
            },
            city: {
                label: "Comune di nascita",
                placeholder: "Inizia a scrivere un comune di nascita",
            },
            fiscalCode: {
                label: "Codice fiscale",
                placeholder: "Inserisci un codice fiscale valido",
            },
            nationalId: {
                label: "Numero documento d'identità estero",
                placeholder: "Inserisci un numero documento d'identità estero",
            },
            citizenship: {
                label: "Cittadinanza",
                placeholder: "Seleziona una cittadinanza",
                it: "Italiana",
                ee: "Estera",
            },
            subscribe:
                "Accetto l'informativa a tutela della privacy e presto il consenso a ricevere comunicazioni da TDF*P.",
            error: {
                cf: {
                    cf: "I dati relativi al codice fiscale non sono corretti. Ti preghiamo di verificare: Nome, Cognome, Sesso, Data di nascita, Paese di nascita, Comune di nascita.",
                    cf2: "Se il problema persiste, ",
                    cflabel: "contattaci.",
                },
            },
        },
        meta: { title: "ISCRIVITI", description: "" },
    },
    subscribe: {
        header: "Tessera",
        title: "Tessera",
        description:
            "Per poter partecipare ad eventi e altre attività promosse dal Tempio, è necessario aver fatto richiesta di tesseramento.",
        instructions:
            "Compila il modulo per iscriverti!\nTroverai la tessera nel tuo profilo.",
        warning:
            "Al tuo primo accesso al Tempio, dovrai presentare un documento d'identità valido.\nLa tessera ha validità di due anni.",
        form: {
            selectCategory: "Seleziona una categoria",
            submit: "Invia",
            firstName: {
                label: "Nome",
                disclaimer:
                    "Se il nome sul documento non corrisponde alla tua identità di genere, non preoccuparti. Questa informazione ci è necessaria solo alla validazione del tuo codice fiscale.",
                placeholder: "Inserisci il tuo nome",
            },
            lastName: {
                label: "Cognome",
                placeholder: "Inserisci il tuo cognome",
            },
            type: {
                label: "Tipo di tessera",
                placeholder: "Seleziona il tipo di tessera",
                description0:
                    "La tessera per gli eventi serali verrà rilasciata in app e potrai esibirla per accedere all'ingresso.",
                description1:
                    "La tessera per corsi, workshop e laboratori verrà rilasciata via mail che potrai esibire per accedere all'ingresso.",
            },
            category: {
                label: "Categoria",
                placeholder: "Seleziona una categoria",
            },
            address: {
                label: "Indirizzo",
                placeholder: "Inserisci un indirizzo",
            },
            country: {
                label: "Paese di nascita",
                placeholder: "Inizia a scrivere una Paese di nascita",
            },
            gender: {
                label: "Sesso biologico",
                placeholder: "Seleziona un sesso biologico",
                disclaimer:
                    "Se il sesso sul documento non corrisponde alla tua identità di genere, non preoccuparti. Non collezioniamo questo dato, ci è necessario solo alla validazione del tuo codice fiscale.",
            },
            dob: {
                label: "Data di nascita",
                placeholder: "Inserisci una data di nascita",
            },
            email: {
                label: "E-mail",
                placeholder: "Inserisci il tuo indirizzo e-mail",
            },
            message: {
                label: "Messaggio",
                placeholder: "Scrivi qui il tuo messaggio",
            },
            city: {
                label: "Comune di nascita",
                placeholder: "Inizia a scrivere un comune di nascita",
            },
            fiscalCode: {
                label: "Codice fiscale",
                placeholder: "Inserisci un codice fiscale valido",
            },
            nationalId: {
                label: "Numero documento d'identità estero",
                placeholder: "Inserisci un numero documento d'identità estero",
            },
            citizenship: {
                label: "Cittadinanza",
                placeholder: "Seleziona una cittadinanza",
                it: "Italiana",
                ee: "Estera",
            },
            subscribe: {
                subscribe: "Ho letto e accetto la",
                privacy: "privacy policy",
                subscribe2: "e acconsento a ricevere comunicazioni da TDF*P.",
                statute1: "Ho letto e accetto integralmente lo",
                movement: "statuto di Movement APS",
                rinascimento: "statuto di Nuovo Rinascimento APS",
                statute2:
                    ", il regolamento interno e le deliberazioni degli organi sociali. Chiedo di poter essere ammesso in qualità di socio ed accetto la processazione dei miei dati personali per le finalità statutarie.",
            },
            error: {
                cf: {
                    cf: "I dati relativi al codice fiscale non sono corretti. Ti preghiamo di verificare: Nome, Cognome, Sesso, Data di nascita, Paese di nascita, Comune di nascita.",
                },
            },
        },
        meta: { title: "ISCRIVITI", description: "" },
    },
    exhibit: {
        header: "Mostre",
    },
    events: {
        header: "Calendario",
        title: "Calendario",
        description: "I prossimi eventi del Tempio",
        calendar: {
            locale: "it",
            dayButton: "Giorno",
            monthButton: "Mese",
            weekButton: "Settimana",
            shortWeekButton: "Sett.",
            listButton: "Lista",
            todayButton: "Oggi",
        },
        noEvents: "Nessun evento in programma per il periodo selezionato",
        meta: { title: "EVENTI", description: "" },
    },
    event: {
        profile: "profilo",
        soldout: "Sold out",
        relatedEvents: "Altro in",
        gotoprofile:
            "Hai già acquistato un biglietto per questo evento. Puoi trovarlo nel tuo",
        alreadybought: "Biglietto acquistato",
        or: "oppure",
        logintobuy: "Accedi per acquistare",
        getmembership: "Richiedi la tessera",
        gotomembership: "Devi essere tesseratə a",
        gotomembership2:
            "per acquistare un biglietto per questo evento. Richiedi la tessera",
        membership: "qui",
        cookies: {
            description:
                "Per poter acquistare un biglietto per questo evento, devi accettare i cookie non essenziali. Per farlo, clicca sul pulsante qui sotto.",
            preferences: "Accetta cookie",
        },
        mustbelogged:
            "Per acquistare un biglietto per questo evento, devi avere un account ed essere tesseratə",
        seats: "posti disponibili",
        shortSeats: "posti",
        tax: "IVA inclusa",
        title: "Evento",
        total: "Prezzo",
        info: "Più info su",
        qty: "Quantità",
        purchase: "Acquista",
        ticket: "Biglietto per",
        price: "Prezzo",
        locale: "it",
        back: "Torna al calendario",
        meta: { title: "EVENTO", description: "" },
        header: "Evento",
    },
    news: {
        header: "News",
        title: "News",
        description: "Le ultime news dal Tempio",
        publishedOn: "Pubblicato il",
        meta: { title: "NEWS", description: "" },
    },
    radio: {
        header: "Radio",
        title: "Radio live",
        status: {
            offline: { title: "Stream offline", description: "Presto in onda" },
        },
        meta: { title: "RADIO", description: "" },
    },
    virtual: {
        header: "Tempio virtuale",
        title: "Tempio virtuale",
        description: "",
        meta: { title: "TEMPIO VIRTUALE", description: "" },
    },
    where: {
        header: "Dove",
        title: "Dove siamo",
        description: "Puoi trovarci in",
        location: "via Luigi Nono 7, 20154 Milano",
        wall: {
            title: "Muro della gentilezza",
            days: "Dal lunedì al venerdì",
            startHour: "h10:30",
            endHour: "h18:30",
            closingDays: "Chiuso il sabato e la domenica",
        },
        meta: { title: "DOVE", description: "" },
    },
    notfound: {
        header: "Pagina non trovata",
        title: "404",
        description:
            "La pagina che stai cercando non esiste, o potrebbe essere stata rimossa.",
        back: "Torna alla home",
        meta: { title: "PAGINA NON TROVATA", description: "" },
    },
    home: {
        title: "TDF*P",
        header: "",
        signup: "Registrati",
        signupHeader: "Entra",
        login: "Accedi",
        membershipRequirement:
            "Per poter partecipare ad eventi e altre attività promosse dal Tempio, è necessario aver fatto richiesta di tesseramento.",
        signupClaim:
            "Se non hai ancora una tessera, puoi richiederne una gratuitamente.",
        loginClaim: "Se invece hai già un account, effettua l'accesso.",
        nextup: "Prossimi eventi",
        announcements: "News",
        featured: "In evidenza",
        noEvents: "Non ci sono eventi in programma per i prossimi giorni.",
    },
    pwdchanged: {
        title: "Password cambiata",
        description: "La tua password è stata cambiata con successo.",
        back: "Riaccedi",
        header: "Password cambiata",
        meta: { title: "PASSWORD CAMBIATA", description: "" },
    },
    activate: {
        account: {
            title: "Attiva account",
            description:
                "Controlla la casella di posta elettronica e clicca sul link che ti abbiamo inviato per attivare il tuo account.",
            back: "Torna alla home",
            header: "Attiva account",
            meta: { title: "ATTIVA ACCOUNT", description: "" },
        },
        pwdchange: {
            title: "Recupera password",
            description:
                "Controlla la casella di posta elettronica e clicca sul link che ti abbiamo inviato per aggiornare la tua password.",
            back: "Torna alla home",
            header: "Recupera password",
            meta: { title: "RECUPERA PASSWORD", description: "" },
        },
    },
    expired: {
        title: "Pagina scaduta",
        description: {
            default: "Il link che hai cliccato è scaduto.",
            accountactivation:
                "Il link che hai seguito è scaduto. Puoi richiedere un nuovo link di attivazione effettuando l'accesso.",
            pwdchange:
                "Il link che hai seguito è scaduto. Puoi richiedere un nuovo link nella sezione Password dimenticata.",
        },
        back: "Torna alla home",
        header: "Pagina scaduta",
        meta: { title: "SCADUTO", description: "" },
    },
    passwordchangesuccess: {
        title: "Password aggiornata",
        description:
            "La tua password è stata cambiata con successo. Verrai reindirizzatə alla pagina di accesso in",
        back: "Accedi",
        header: "Password aggiornata",
        meta: {
            title: "PASSWORD AGGIORNATA",
            description: "",
        },
    },
    registersuccess: {
        title: "Account attivato",
        description:
            "Il tuo account è stato attivato con successo. Verrai reindirizzatə alla pagina di accesso in",
        back: "Accedi",
        header: "Account attivato",
        meta: { title: "ACCOUNT ATTIVATO", description: "" },
    },
    thankyou: {
        title: "Grazie!",
        header: "Grazie!",
        description:
            "Abbiamo inviato un messaggio di conferma all'indirizzo e-mail inserito. A presto!",
        back: "Torna alla home",
        meta: { title: "GRAZIE", description: "" },
    },
    preferences: {
        header: "Preferenze cookie",
        title: "Preferenze cookie",
        description: "",
        meta: { title: "PREFERENZE", description: "" },
    },
    statute: {
        movement: "Statuto Movement",
        rinascimento: "Statuto Nuovo Rinascimento",
    },
    privacy: {
        header: "Privacy policy",
        title: "Privacy policy",
        description: "",
        meta: { title: "PRIVACY POLICY", description: "" },
    },
    eula: {
        header: "EULA",
        title: "Accordo di licenza per l'utente finale",
        description: "",
        meta: { title: "EULA", description: "" },
    },
    refunds: {
        header: "Informativa sui rimborsi",
        title: "Informativa sui rimborsi",
        description: "",
        meta: { title: "RIMBORSI", description: "" },
    },
    footer: {
        legal: "Termini",
    },
    terms: {
        header: "Termini e condizioni",
        title: "Termini e condizioni",
        description: "",
        meta: { title: "TERMINI E CONDIZIONI", description: "" },
    },
    cookies: {
        header: "Cookie policy",
        title: "Cookie policy",
        description: "",
        meta: { title: "COOKIE POLICY", description: "" },
    },
    components: {
        colorPicker: {
            darkLabel: "Tema scuro",
            lightLabel: "Tema chiaro",
        },
        homeCarousel: {
            paragraph:
                "Una meravigliosa serenità, simile a questo dolce mattino di primavera, mi è scesa nell'anima e io ne godo con tutto il mio cuore. Sono solo e sono lieto di essere vivo in questo luogo creato per anime come la mia.",
        },
    },
};
